.containerApp {
  display: grid;
  padding: 20px;
  justify-items: center;
  grid-template-columns: 1fr;
  gap: 20px;
  line-height: normal;
}

.containerSerwis {
  /* padding: 20px; */
  display: flex;
  padding: 20px;
  margin-top: 8vh; 
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  height: 84vh;
}

.disabled {
  pointer-events: none; /* Blokuje interakcję użytkownika */
  opacity: 0.5; /* Wygląd "wyłączonego" elementu */
}

@media (min-width: 600px) {
  .containerApp {
    grid-template-columns: 1fr 1fr;
  }
}

.p-card .p-card-content {
  padding: 0.5rem 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card {
  animation: none;
  display: flex;
  align-items: center;
  line-height: 1.0;
  width: 100%;
  /* overflow: auto; */
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  /* margin: 10px; */
  /* padding: 10px; */
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-115%);
  }
}

.card.slide-out {
  animation: slideOutLeft 0.5s forwards;
}

.form-container {
  position: absolute;
  left: 100%;
  padding: 0px;
  margin: 10px;
  top: 0; /* Ustawienie formularza na tej samej wysokości co karta */
  max-width: 95%; /* Formularz zajmuje całą szerokość kontenera */
  /* max-width: 500px; Maksymalna szerokość formularza */
  opacity: 0;
  overflow-y: auto; /* Umożliw przewijanie wewnątrz formularza, jeśli zawartość przekracza maksymalną wysokość */
  transition: opacity 0.5s, left 0.5s;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.form-container.show {
  left: 0;
  opacity: 1;
}

.expander
{
  width: 1px;
}
/* .logoGrupa {
    height: 8vmin;
  } */

/* .menubar {
    margin-bottom: 3px;
    height: 80px;
  } */
